import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/1oYLmLRpDTM">
    <SEO title="Why Preach the Bible? - Why Church?" />
  </Layout>
)

export default SermonPost
